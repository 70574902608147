import React, { useEffect, useState } from "react";
import Mapa from "../components/UI/components/Mapa";
import useProductos from "../hooks/useProductos";
import { useNavigate } from "react-router-dom";

const GetLocation = () => {
    const {
        latitude,
        longitude,
        getLocation,
        guardarUbicacion,
        userUbication,
        handleMostrarCambioUbicacion,
        handleLatitude,
        handleLongitude,
    } = useProductos();
    const [step, setStep] = useState(1);
    const [currentCoords, setCurrentCoords] = useState({
        lat: latitude,
        lng: longitude,
    });

    const [direccion, setDireccion] = useState("");
    const [direccionGoogle, setdireccionGoogle] = useState("")
    const [referencia, setReferencia] = useState("");
    const [telefono, setTelefono] = useState("");

    useEffect(() => {
        if (step === 2 && !latitude && !longitude) {
            getLocation();
        }
    }, [step, latitude, longitude, getLocation]);

    const navigate = useNavigate();

    // Función para actualizar la ubicación del cliente
    const setUbicacionCliente = (newCoords) => {
        setCurrentCoords(newCoords);
    };

    

    async function obtenerDireccion(latitud, longitud) {
        const apiKey = process.env.REACT_APP_GOOGLE_API_KEY; // Reemplaza con tu propia clave de API
        const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitud},${longitud}&key=${apiKey}`;

        try {
            const response = await fetch(url);
            const data = await response.json();

            if (data.status === "OK" && data.results.length > 0) {
                // Obtener la dirección formateada
                let direccion = data.results[0].formatted_address;

                // Eliminar parte de la dirección que contiene la ciudad y el país
                const partesDireccion = direccion.split(", ");
                direccion = partesDireccion[0]; // Tomar solo la primera parte de la dirección

                return direccion;
            } else {
                throw new Error("No se pudo obtener la dirección");
            }
        } catch (error) {
            console.error("Error al obtener la dirección:", error);
            return null;
        }
    }

    useEffect(() => {
        // Verifica si hay valores en gps y currentStep es igual a 4, y direccion está vacío
        if (step === 3 ) {
            // Separa latitud y longitud de gps
            //const [latitud, longitud] = gps.split(",");

            // Llama a la función obtenerDireccion y maneja el resultado
            obtenerDireccion(currentCoords.lat, currentCoords.lng)
                .then((direccion) => {
                    
                    // Establece la dirección en el estado
                    setDireccion(direccion);
                    setdireccionGoogle(direccion)
                })
                .catch((error) => {
                    console.error("Error al obtener la dirección:", error);
                });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step]);

    useEffect(() => {
        if (userUbication && !direccion) {
          setDireccion(userUbication.direccion);
          setReferencia(userUbication.referencia);
          setTelefono(userUbication.telefono);
        }
      }, [userUbication, direccion]);
    

    return (
        <div className="container-obtener-ubicacion">
            <div>
                {step === 1 && (
                    <div className="container-mensaje-ubicacion">
                        <h1>
                            Hola!!, para empezar, por favor acepta el permiso de ubicación
                        </h1>
                        <h4>
                            Esto nos sirve para poder entregar tus pedidos de
                            forma rápida y precisa
                        </h4>
                        <button
                            className="button-entendido-ubicacion"
                            onClick={() => setStep(2)}
                        >
                            Entendido
                        </button>
                    </div>
                )}
            </div>
            {step === 2 && (
                <>
                    {latitude && longitude ? (
                        <div className="mapa">
                            <Mapa
                                coordenadas={{ lat: latitude, lng: longitude }}
                                onMarkerPositionChange={setUbicacionCliente} // Pasar la función de callback
                            />
                        </div>
                    ) : null}

                    <div>
                        <p>
                            Mueve el mapa hasta que el marcador esté en la
                            ubicación que deseas para la entrega.
                        </p>

                        <button
                            type="button"
                            onClick={() => {
                                // guardarUbicacion(
                                //     currentCoords.lat,
                                //     currentCoords.lng,
                                //     "",
                                //     "",
                                //     ""
                                // ); // Puedes pasar strings vacíos si no tienes dirección y referencia

                                setStep(3); // Cambiar el paso a 3
                            }}
                            className="button-ubicacion"
                        >
                            Perfecto! Es mi ubicación actual
                        </button>
                    </div>
                </>
            )}
            {step === 3 && (
                <div className="container-mensaje-ubicacion">
                    <div>
                        <h2>Ya casi estamos, solo necesitamos estos datos</h2>
                    </div>
                    <div>
                        <h4>Direccion</h4>
                        <p>Indicanos tu direccion exacta</p>
                        <input
                            type="text"
                            className="input-direccion"
                            placeholder="Ejm: Av. luzuriaga 123"
                            value={direccion} // Asignar valor del estado
                            onChange={(e) => setDireccion(e.target.value)}
                        />
                    </div>
                    <div>
                        <h4>Referencia</h4>
                        <p>Danos alguna referencia de tu domicilio</p>
                        <input
                            type="text"
                            className="input-referencia"
                            placeholder="Ejm: a espaldas del colegio tomas valle"
                            value={referencia} // Asignar valor del estado
                            onChange={(e) => setReferencia(e.target.value)}
                        />
                    </div>
                    <div>
                        <h4>Telefono</h4>
                        <p>
                            A que numero debemos comunicarnos para entregar tu
                            pedido
                        </p>
                        <input
                            type="tel"
                            className="input-telefono"
                            placeholder="Ejm: 987654321"
                            value={telefono} // Asignar valor del estado
                            onChange={(e) => setTelefono(e.target.value)}
                        />
                    </div>

                    <button
                        type="button"
                        className="button-guardar-direccion"
                        onClick={() => {
                            guardarUbicacion(
                                currentCoords.lat,
                                currentCoords.lng,
                                direccion,
                                direccionGoogle,
                                referencia,
                                telefono
                            ); // Puedes pasar strings vacíos si no tienes dirección y referencia

                            navigate("/");
                            handleMostrarCambioUbicacion(true);
                            localStorage.setItem(
                                "latitude",
                                currentCoords.lat.toString()
                            );
                            localStorage.setItem(
                                "longitude",
                                currentCoords.lng.toString()
                            );
                            handleLatitude(currentCoords.lat);
                             handleLongitude(currentCoords.lng);
                            // Cambiar el paso a 3
                        }}
                    >
                        Continuar
                    </button>
                </div>
            )}
        </div>
    );
};

export default GetLocation;
