import React from "react";
import { ListGroupItem } from "reactstrap";

import "../../../styles/cart-item.css";

import { useDispatch } from "react-redux";
import { cartActions } from "../../../store/shopping-cart/cartSlice";
import { RiDeleteBin6Fill } from "react-icons/ri";
import useProductos from "../../../hooks/useProductos";
import { useEffect } from "react";

const CartItem = ({ item }) => {
    const {
        id,
        name,
        price,
        cover,
        quantity,
        totalPrice,
        itemId,
        prodMaster,
        opcion,
    } = item;

    const dispatch = useDispatch();

    const { tiendas, obtenerTiendas } = useProductos();

    useEffect(() => {
        obtenerTiendas();
    }, []);

    const incrementItem = () => {
        dispatch(
            cartActions.addItem({
                id,
                name,
                price,
                cover,
                itemId,
                prodMaster,
                opcion,
            })
        );
    };

    const decreaseItem = () => {
        dispatch(cartActions.removeItem(id));
    };

    const deleteItem = () => {
        dispatch(cartActions.deleteItem(id));
    };

    const getTiendaNameByItemId = (itemId) => {
        const tiendaEncontrada = tiendas.find(
            (tienda) => tienda._id === itemId
        );
        return tiendaEncontrada
            ? tiendaEncontrada.nombre
            : "Tienda no encontrada";
    };

    return (
        <ListGroupItem className="cart__item item-cart-borde">
            <div className="cart__item-info d-flex gap-2">
                {item.cover && <img src={cover} alt="product-img" />}

                <div className="cart__product-info w-100 d-flex align-items-center gap-4 justify-content-between">
                    <div>
                        <h6 className="cart__product-title">
                            {prodMaster} {prodMaster && " - "}
                            {name} - {opcion} - {getTiendaNameByItemId(itemId)}
                        </h6>

                        <p className=" d-flex align-items-center gap-5 cart__product-price">
                            {quantity} x <span>S/.{(totalPrice).toFixed(2)} </span>
                        </p>
                        <div className=" d-flex align-items-center justify-content-between increase__decrease-btn">
                            <span
                                className="decrease__btn"
                                onClick={decreaseItem}
                            >
                                <i class="ri-subtract-line"></i>
                            </span>
                            <span className="quantity">{quantity}</span>

                            <span
                                className="increase__btn"
                                onClick={incrementItem}
                            >
                                <i class="ri-add-line"></i>
                            </span>
                        </div>
                    </div>

                    <span className="delete__btn" onClick={deleteItem}>
                        <RiDeleteBin6Fill className="boton-eliminar" />
                    </span>
                </div>
            </div>
        </ListGroupItem>
    );
};

export default CartItem;
