import useProductos from "../../hooks/useProductos";
import { RiMapPinLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

const Ubication = () => {
    const navigate = useNavigate()
    const { userUbication } = useProductos();

    return (
        <div  className="ubication-div sticky-ubication">

            <div className="container-texto-direccion">
                <p className="p-tu-direccion">Tu direccion actual</p>
                <p className="p-direccion">
                    <RiMapPinLine />
                    {userUbication.direccionGoogle}
                </p>
            </div>
            <div className="div-p-cambiar-direccion">
                <p onClick={()=>navigate("/ubicacion")} className="p-cambiar">cambiar</p>
            </div>
        </div>
    );
};

export default Ubication;
