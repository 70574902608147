import React, { useEffect, useState } from "react";
import { useSelector,useDispatch } from "react-redux";
import { Container, Row, Col  } from "reactstrap";
import Helmet from "../components/Helmet/Helmet";
import yapeImg from "../assets/images/yape.png";
import plinImg from "../assets/images/plin.webp";
import efectivoImg from "../assets/images/efectivoImg.jpg";
import { obtenerFechaActual, obtenerHoraActual } from "../helpers/dateHelper";
import { useNavigate } from "react-router-dom";

import "../styles/checkout.css";
import useProductos from "../hooks/useProductos";
import {
    calculateDistanceAndPrice,
    polygonPoints,
} from "../helpers/calculateDistanceandPrice";
import Swal from "sweetalert2";
import { cartActions } from "../store/shopping-cart/cartSlice";

const Checkout = () => {
    const cartItems = useSelector((state) => state.cart.cartItems);
    const dispatch = useDispatch();

    const [enterName, setEnterName] = useState("");
    const [enterNumber, setEnterNumber] = useState("");
    const [enterCountry, setEnterCountry] = useState("");
    const [pagaCon, setPagaCon] = useState("");
    const [rSelected, setRSelected] = useState("Efectivo");
    const [itemIds, setItemIds] = useState([]);
    const [gpsArray, setGpsArray] = useState([]);
    const [delivery, setDelivery] = useState(0);
    const [selectedPayment, setSelectedPayment] = useState("efectivo");
    const [notaAdicional, setNotaAdicional] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false); 

    const shippingInfo = [];
    const cartTotalAmount = useSelector((state) => state.cart.totalAmount);

    const shippingCost = 0;

    const totalAmount = cartTotalAmount + Number(shippingCost);

    const {
        tiendas,
        obtenerTiendas,
        latitude,
        longitude,
        userUbication,
        nuevaOrdenCliente,
        comApp,
    } = useProductos();

    useEffect(() => {
        obtenerTiendas();
    }, []);

    const getTiendaNameByItemId = (itemId) => {
        const tiendaEncontrada = tiendas.find(
            (tienda) => tienda._id === itemId
        );
        return tiendaEncontrada
            ? tiendaEncontrada.nombre
            : "Tienda no encontrada";
    };

    useEffect(() => {
        // Aquí podrías obtener tus productos de alguna API o de otro lugar
        // Por ahora, estoy simulando un arreglo de productos

        // Ahora filtramos los itemId únicos
        const itemIdSet = new Set();
        const itemIds = cartItems
            .map((producto) => {
                if (!itemIdSet.has(producto.itemId)) {
                    itemIdSet.add(producto.itemId);
                    return producto.itemId;
                }
                return null;
            })
            .filter((itemId) => itemId !== null);

        // Actualizamos el estado con los itemIds únicos
        setItemIds(itemIds);
    }, [cartItems]);

    const constructWhatsAppMessage = () => {
        // Construir el mensaje con los elementos del carrito

        const groupedItems = cartItems.reduce((acc, item) => {
            const tiendaName = getTiendaNameByItemId(item.itemId);

            // Si el nombre de la tienda no está en el acumulador, lo inicializamos
            if (!acc[tiendaName]) {
                acc[tiendaName] = [];
            }

            // Agregamos el item al array de la tienda correspondiente
            acc[tiendaName].push(item);

            return acc;
        }, {});

        const itemsMessage = Object.entries(groupedItems)
            .map(([tienda, items]) => {
                // Crear un mensaje para cada tienda
                const itemsPorTienda = items
                    .map((item) => {
                        const productMaster = item.prodMaster
                            ? item.prodMaster
                            : "";
                        return ` - x${item.quantity} ${productMaster} ${item.name}`;
                    })
                    .join("%0A");

                return `Tienda: ${tienda}%0A${itemsPorTienda}%0A`;
            })
            .join("%0A");
        // Número de WhatsApp específico al que enviar el mensaje
        const phoneNumber = "+51952686550";

        // Combinar el mensaje con información adicional si es necesario
        const additionalInfo = `%F0%9F%91%8B Hola,vengo de app.warasdelivery.com %0A %F0%9F%93%85 ${obtenerFechaActual()} %E2%8F%B0 ${obtenerHoraActual()} %0A%0A *Tipo de servicio: Domicilio* %0A%0A%F0%9F%93%9DPedido%0A`;
        const postInfo = `%0ATelefono: ${userUbication.telefono}%0ADireccion: ${userUbication.direccion}%0AReferencia:  ${userUbication.referencia} `;
        const costosMsg = `%F0%9F%92%B2 Costos%0A Costo de los productos:S/. ${cartTotalAmount}%0A Costo de delivery: ${delivery}%0A %0A Total:S/. ${
            cartTotalAmount + delivery
        }`;

        // URL del enlace de WhatsApp con el mensaje codificado
        const whatsappURL = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${
            additionalInfo +
            itemsMessage +
            "%0A%0A" +
            costosMsg +
            "%0A%0A" +
            postInfo
        }`;

        // Redirigir al enlace de WhatsApp
        window.location.href = whatsappURL;
    };

    const submitHandler = (e) => {
        e.preventDefault();
        const userShippingAddress = {
            name: enterName,
            phone: enterNumber,
            country: enterCountry,
            pagaCon,
        };

        shippingInfo.push(userShippingAddress);
    };

    useEffect(() => {
        const gpsArray = itemIds
            .map((itemId) => {
                // Busca la tienda correspondiente al itemId actual
                const tienda = tiendas.find((tienda) => tienda._id === itemId);

                // Si la tienda se encuentra, devuelve su gps, de lo contrario, devuelve null
                return tienda ? tienda.gps : null;
            })
            .filter((gps) => gps !== null); // Filtra los resultados que no son null

        // Actualiza el estado con los gps encontrados
        setGpsArray(gpsArray);
    }, [itemIds, tiendas]);

    //algortimo de haversine

    // Define las coordenadas de inicio y fin

    const parsedCoordinates = gpsArray.map((coordStr) => {
        const [lat, lng] = coordStr.split(",").map(parseFloat);
        return { lat, lng };
    });

    const lastPoint = { lat: latitude, lng: longitude };

    const distancesAndPrices = [];

    for (let i = 0; i < parsedCoordinates.length; i++) {
        const startCoord = parsedCoordinates[i];
        const endCoord =
            i === parsedCoordinates.length - 1
                ? lastPoint
                : parsedCoordinates[i + 1];

        if (endCoord) {
            const distanceAndPrice = calculateDistanceAndPrice(
                startCoord,
                endCoord,
                polygonPoints
            );
            distancesAndPrices.push(distanceAndPrice);
        }
    }

    // Imprime los resultados
    useEffect(() => {
        // Verifica si latitude y longitude son ambos 0
        if (latitude === 0 && longitude === 0) {
            // Establece delivery en 0 y sale de la función
            setDelivery(0);
            return;
        }

        // Verifica si hay elementos en distancesAndPrices
        if (distancesAndPrices.length > 0) {
            // Calcula la suma de los precios en distancesAndPrices
            const totalDelivery = distancesAndPrices.reduce(
                (total, distanceAndPrice) => {
                    return total + distanceAndPrice.price;
                },
                0
            );

            // Establece el valor de delivery como la suma calculada
            setDelivery(totalDelivery);
        } else {
            // Si no hay elementos en distancesAndPrices, establece delivery en 0 o en algún otro valor predeterminado
            setDelivery(0); // O cualquier otro valor predeterminado
        }
    }, [distancesAndPrices, latitude, longitude, userUbication]);

    const handlePaymentSelect = (paymentType) => {
        setSelectedPayment(paymentType);
        
    };

    const navigate = useNavigate();
    

    const handleNuevaOrdenCliente = async () => {
        // Evitar múltiples envíos deshabilitando el botón
        setIsSubmitting(true);

        // Mostrar el modal de "Enviando pedido"
        Swal.fire({
            title: "Enviando pedido...",
            text: "Por favor espera mientras procesamos tu pedido",
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading(); // Muestra el icono de cargando
            },
        });

        try {
            // Mapeamos el arreglo de cartItems para extraer los itemId, cantidad, opciones y precios
            const pedido = cartItems.map((item) => {
                const idParts = item.id.split("-"); // Dividimos el ID para obtener el ID del producto
                return {
                    producto: idParts[0], // ID del producto (antes del guion)
                    cantidad: item.quantity, // Cantidad del producto
                    opcion: item.opcion || "", // Opciones del producto, si existen
                    price: item.price, // Precio unitario del producto
                    totalPrice: item.totalPrice, // Precio total considerando cantidad * price
                };
            });

            // Calculamos el monto cobrado por la app (comApp)
            const montoCobradoApp = cartTotalAmount; // O el cálculo que utilices para obtener este valor

            // Preparamos los datos para enviar
            const nuevaOrden = {
                tipoServicio: "domicilio", // Tipo de servicio, como 'domicilio' o 'recojo'
                tipoPedido: "app",
                horaDeseada: "", // Hora deseada por el cliente
                pedido, // Aquí está la lista de productos con los detalles actualizados
                estado: "pendiente", // Estado inicial de la orden
                notasCliente: notaAdicional, // Notas adicionales del cliente
                montoDelivery: delivery, // Monto del delivery
                montoCobradoApp, // Monto cobrado por la app (comisión)
                telefono: userUbication.telefono, // Teléfono del cliente
                direccion: userUbication.direccion, // Dirección del cliente
                coordenadas: `${userUbication.lat}, ${userUbication.long}`, // Coordenadas en formato cadena
                metodoDePago: selectedPayment, // Método de pago seleccionado
                pagaCon: pagaCon, // Dinero con el que el cliente pagará (ej. si pagará con billete grande)
            };

            // Enviamos la orden al servidor
            await nuevaOrdenCliente(nuevaOrden);

            dispatch(cartActions.clearCart());
            setTimeout(() => {
                constructWhatsAppMessage();
            }, 3000);
             // Llama a la función para enviar la orden por WhatsApp

            

            // Después de enviar con éxito, cerrar el modal y redirigir
            Swal.fire({
                icon: "success",
                title: "Pedido enviado",
                text: "Tu pedido ha sido enviado exitosamente",
                showConfirmButton: false,
                timer: 3000, // Cierra automáticamente en 3 segundos
            }).then(() => {
                navigate("/"); // Navegar a la página de inicio después de 3 segundos
            });
        } catch (error) {
            console.error("Error al crear la orden del cliente:", error);

            // Mostrar un modal de error en caso de que falle
            Swal.fire({
                icon: "error",
                title: "Error al enviar",
                text: "Hubo un problema al enviar tu pedido. Inténtalo de nuevo.",
                showConfirmButton: false,
                timer: 3000, // Cierra automáticamente en 3 segundos
            });
        } finally {
            // Volver a habilitar el botón al finalizar (ya sea exitoso o con error)
            setIsSubmitting(false);
        }
    };

    return (
        <Helmet title="Checkout">
            <section>
                <Container>
                    <Row>
                        <Col lg="8" md="6">
                            <form
                                className="checkout__form"
                                onSubmit={submitHandler}
                            >
                                <div>
                                    <div>
                                        <h1 className="text-resumen">
                                            Resumen :
                                        </h1>

                                        <div className="product-summary-table">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Cant.</th>
                                                        <th>Producto</th>
                                                        <th>Tienda</th>
                                                        <th>Precio Unitario</th>
                                                        <th>Precio Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {cartItems.map((item) => (
                                                        <tr key={item.id}>
                                                            <td>
                                                                x{item.quantity}
                                                            </td>
                                                            <td className="td-producto">
                                                                {item.name}
                                                            </td>
                                                            <td className="td-local">
                                                                {getTiendaNameByItemId(
                                                                    item.itemId
                                                                )}
                                                            </td>
                                                            <td>
                                                                s/.{" "}
                                                                {item.price.toFixed(
                                                                    1
                                                                )}
                                                            </td>
                                                            <td>
                                                                s/.{""}
                                                                {(
                                                                    item.price *
                                                                    item.quantity
                                                                ).toFixed(1)}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>

                                        <div className="checkout-summary-card">
                                            <div className="checkout-card-body">
                                                <div className="checkout-summary-item">
                                                    <span>Subtotal:</span>
                                                    <span>
                                                        S/. {cartTotalAmount}
                                                    </span>
                                                </div>

                                                <div className="checkout-summary-item">
                                                    <span>Delivery:</span>
                                                    <span>S/. {delivery}</span>
                                                </div>

                                                <div className="checkout-summary-total">
                                                    <strong>Total:</strong>
                                                    <strong>
                                                        S/.{" "}
                                                        {cartTotalAmount +
                                                            delivery}
                                                    </strong>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="payment-buttons">
                                            <button
                                                type="button"
                                                className={`payment-button ${
                                                    selectedPayment ===
                                                    "efectivo"
                                                        ? "active"
                                                        : ""
                                                }`}
                                                onClick={() =>
                                                    handlePaymentSelect(
                                                        "efectivo"
                                                    )
                                                }
                                            >
                                                <img
                                                    src={efectivoImg}
                                                    alt="Efectivo"
                                                />
                                                <span>Efectivo</span>
                                            </button>
                                            <button
                                                type="button"
                                                className={`payment-button ${
                                                    selectedPayment === "yape"
                                                        ? "active"
                                                        : ""
                                                }`}
                                                onClick={() =>
                                                    handlePaymentSelect("yape")
                                                }
                                            >
                                                <img src={yapeImg} alt="Yape" />
                                                <span>Yape</span>
                                            </button>
                                            <button
                                                type="button"
                                                className={`payment-button ${
                                                    selectedPayment === "plin"
                                                        ? "active"
                                                        : ""
                                                }`}
                                                onClick={() =>
                                                    handlePaymentSelect("plin")
                                                }
                                            >
                                                <img src={plinImg} alt="Plin" />
                                                <span>Plin</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                {selectedPayment === "efectivo" && (
                                    <div className="form__group">
                                        <label className="user__name">
                                            Con cuanto paga
                                        </label>
                                        <div className="input-prefix">
                                            <span className="prefix">S/.</span>
                                            <input
                                                type="tel"
                                                placeholder="Ejm: 50"
                                                value={pagaCon}
                                                onChange={(e) =>
                                                    setPagaCon(e.target.value)
                                                }
                                            />
                                        </div>
                                    </div>
                                )}

                                <div className="form__group">
                                    <label className="user__name">
                                        Notas adicionales
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="Ejm: sin mayonesa, sin mostaza"
                                        onChange={(e) =>
                                            setNotaAdicional(e.target.value)
                                        }
                                    />
                                </div>

                                <button
                                    type="submit"
                                    className="button-send"
                                    onClick={() => handleNuevaOrdenCliente()}
                                >
                                    Enviar pedido
                                </button>
                            </form>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Helmet>
    );
};

export default Checkout;
