import Layout from "./components/Layout/Layout";
import { ProductosProvider } from "./context/ProductosProvider";

function App() {
  return (

    <ProductosProvider>
      <Layout />
    </ProductosProvider>



  );
}

export default App;
