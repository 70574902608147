export const fecha = new Date()


export const hora = fecha.getHours();
export const diaSemana = fecha.getDay()
export const diaAjustado = (diaSemana + 1) % 7;  




