import React from "react";

import CommonSection from "../components/UI/common-section/CommonSection";
import Helmet from "../components/Helmet/Helmet";
import "../styles/cart-page.css";
import { useSelector, useDispatch } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import { cartActions } from "../store/shopping-cart/cartSlice";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import useProductos from "../hooks/useProductos";

const Cart = () => {

    const {handleLocalState,tiendas} = useProductos()

    useEffect(() => {
        handleLocalState()
    }, [])

    const navigate = useNavigate()

    
    
    const cartItems = useSelector((state) => state.cart.cartItems);
    const totalAmount = useSelector((state) => state.cart.totalAmount);

    const handleBack = () => {
        navigate(-1)
    }
    return (
        <Helmet title="Cart">
            <CommonSection title="Mi carrito" />
            <section>
                <Container>
                    <Row>
                        <Col lg="12">
                            {cartItems.length === 0 ? (
                                <h5 className="text-center">
                                    Oops!, Tu carrito esta vacio
                                </h5>
                            ) : (
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Nombre</th>
                                            <th>Precio</th>
                                            <th>Cantidad</th>
                                            <th>Eliminar</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {cartItems.map((item) => (
                                            <Tr item={item} key={item.id} />
                                        ))}
                                    </tbody>
                                </table>
                            )}

                            <div className="mt-4">
                                <h6>
                                    Subtotal: S/.
                                    <span className="cart__subtotal">
                                        {(totalAmount).toFixed(2)}
                                    </span>
                                </h6>
                                <h5>
                                    Cargos y costo de delivery será calculado al
                                    final de la compra.
                                </h5>
                                <div className="cart__page-btn">
                                    <button onClick={handleBack} className="addTOCart__btn me-4">
                                        
                                            Continuar comprando
                                        
                                    </button>
                                    <button className="addTOCart__btn">
                                        <Link to="/checkout">
                                            Confirmar compra{" "}
                                        </Link>
                                    </button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Helmet>
    );
};

const Tr = (props) => {
    const { id, cover, name, price, quantity, itemId, prodMaster } = props.item;
    const dispatch = useDispatch();

    const {handleLocalState,tiendas} = useProductos()

    const deleteItem = () => {
        dispatch(cartActions.deleteItem(id));
    };

    const getTiendaNameByItemId = (itemId) => {
        const tiendaEncontrada = tiendas.find((tienda) => tienda._id === itemId);
        return tiendaEncontrada ? tiendaEncontrada.nombre : "Tienda no encontrada";
    };
    return (
        <tr>
            <td className="text-center nombre-prod-carr">
                {prodMaster}   {name}
                <p className="nombre-itemid-carr">{getTiendaNameByItemId(itemId)}</p>
            </td>

            <td className="text-center precio-prod-carr">S/.{(price).toFixed(2)}</td>

            <td className="text-center">{quantity} Und.</td>
            <td className="text-center cart__item-del">
                <i class="ri-delete-bin-line" onClick={deleteItem}></i>
            </td>
        </tr>
    );
};

export default Cart;
