import React, { useRef, useEffect, useState } from "react";

import { Container } from "reactstrap";
import logo from "../../assets/images/warasBlack.png";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RiShoppingCart2Fill, RiMenu2Fill } from "react-icons/ri";

import { cartUiActions } from "../../store/shopping-cart/cartUiSlice";
import useProductos from "../../hooks/useProductos";

import "../../styles/header.css";
import Ubication from "./Ubication";

const nav__links = [
    {
        display: "Restaurantes",
        path: "/home",
    },

    {
        display: "Carrito",
        path: "/cart",
    },
];

const Header = () => {
    const menuRef = useRef(null);
    const headerRef = useRef(null);
    const [animateCart, setAnimateCart] = useState(false);
    const totalQuantity = useSelector((state) => state.cart.totalQuantity);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { userUbication } = useProductos();

    

    const toggleMenu = () => menuRef.current.classList.toggle("show__menu");

    const toggleCart = () => {
        dispatch(cartUiActions.toggle());
    };

    useEffect(() => {
        const handleScroll = () => {
            if (
                document.body.scrollTop > 80 ||
                document.documentElement.scrollTop > 80
            ) {
                headerRef.current.classList.add("header__shrink");
            } else {
                headerRef.current.classList.remove("header__shrink");
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    useEffect(() => {
        if (totalQuantity > 0) {
            setAnimateCart(true);
            const timeout = setTimeout(() => {
                setAnimateCart(false);
            }, 1500);
            return () => clearTimeout(timeout);
        }
    }, [totalQuantity]);





    return (
        <>
            <header className="header" ref={headerRef}>
                <Container>
                    <div className="nav__wrapper d-flex align-items-center justify-content-between">
                        <div className="logo__wrapper">
                            <div onClick={() => navigate("/")} className="logo">
                                <img src={logo} alt="logo" />
                            </div>

                            {/* <div className="user__location">
                    {userUbication && <p>{userUbication.direccion}</p>}
                </div> */}
                        </div>

                        {/* ======= menu ======= */}
                        <div
                            className="navigation"
                            ref={menuRef}
                            onClick={toggleMenu}
                        >
                            <div className="menu d-flex align-items-center gap-5">
                                {nav__links.map((item, index) => (
                                    <NavLink
                                        to={item.path}
                                        key={index}
                                        className={(navClass) =>
                                            navClass.isActive
                                                ? "active__menu"
                                                : ""
                                        }
                                    >
                                        {item.display}
                                    </NavLink>
                                ))}
                            </div>
                        </div>

                        {/* ======== nav right icons ========= */}
                        <div className="nav__right d-flex align-items-center gap-4">
                            <span className="cart__icon" onClick={toggleCart}>
                                <RiShoppingCart2Fill
                                    className={`icono-carrito ${
                                        animateCart
                                            ? "icono-carrito--animated"
                                            : ""
                                    }`}
                                />
                                <span className="cart__badge">
                                    {totalQuantity}
                                </span>
                            </span>

                            <span className="mobile__menu" onClick={toggleMenu}>
                                <RiMenu2Fill className="icono-menu" />
                            </span>
                        </div>
                    </div>
                </Container>
                
            </header>

            {userUbication?.lat && userUbication?.long && <Ubication />}

            

           
        </>
    );
};

export default Header;
