import axios from 'axios';
import dotenv from 'dotenv';

// Cargar las variables de entorno
dotenv.config();

const clienteAxios = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL || 'http://localhost:4000'
});

export default clienteAxios;
