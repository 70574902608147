import React from "react";

import { ListGroup } from "reactstrap";
import { Link } from "react-router-dom";
import CartItem from "./CartItem";

import { useDispatch, useSelector } from "react-redux";
import { cartUiActions } from "../../../store/shopping-cart/cartUiSlice";

import "../../../styles/shopping-cart.css";

const Carts = () => {
    const dispatch = useDispatch();
    const cartProducts = useSelector((state) => state.cart.cartItems);
    const totalAmount = useSelector((state) => state.cart.totalAmount);

    const toggleCart = () => {
        dispatch(cartUiActions.toggle());
    };

    
    return (
        <div className="cart__container">
            <ListGroup className="cart">
                <div className="cart__close">
                    <span onClick={toggleCart}>
                        <i className="ri-close-fill"></i>
                    </span>
                </div>

                <div className="cart__item-list">
                    {cartProducts.length === 0 ? (
                        <h6 className="text-center mt-5">
                            Tu carrito está vacio
                        </h6>
                    ) : (
                        cartProducts.map((item, index) => (
                            <CartItem item={item} key={index} />
                        ))
                    )}
                </div>

                <div className="cart__bottom d-flex align-items-center justify-content-between">
                    <h6>
                        Subtotal : <span>S/.{(totalAmount).toFixed(2)}</span>
                    </h6>

                    <Link to="/checkout" onClick={toggleCart}>
                        <button className="boton-confirmar">Confirmar compra</button>
                    </Link>
                </div>
            </ListGroup>
        </div>
    );
};

export default Carts;
