import { useState, useEffect, createContext } from "react";
import clienteAxios from "../config/clienteAxios";
import { logDOM } from "@testing-library/react";

const ProductosContext = createContext();

const ProductosProvider = ({ children }) => {
    const comApp = 1;
    const [tiendas, setTiendas] = useState(
        JSON.parse(localStorage.getItem("tiendas")) || []
    );

    const [mostrarCambioUbicacion, setMostrarCambioUbicacion] = useState(false);
    const [local, setLocal] = useState([]);
    const [productos, setProductos] = useState([]);
    const [tiendaVista, setTiendaVista] = useState("");
    const [modalAgregarProducto, setModalAgregarProducto] = useState(false);
    const [productosPorCategoria, setProductosPorCategoria] = useState([]);
    const [categoriaSelected, setCategoriaSelected] = useState("");
    const [producto, setProducto] = useState([]);
    const [cargando, setCargando] = useState(false);
    const [userUbication, setUserUbication] = useState(() => {
        const ubicacionGuardada = localStorage.getItem("userUbication");
        return ubicacionGuardada ? JSON.parse(ubicacionGuardada) : {};
    });
    const [latitude, setLatitude] = useState(() => {
        const storedValue = localStorage.getItem("latitude");
        return storedValue ? parseFloat(storedValue) : 0;
    });
    const [longitude, setLongitude] = useState(() => {
        const storedValue = localStorage.getItem("longitude");
        return storedValue ? parseFloat(storedValue) : 0;
    });

    const guardarUbicacion = (
        lat,
        long,
        direccion,
        direccionGoogle,
        referencia,
        telefono
    ) => {
        const nuevaUbicacion = {
            lat,
            long,
            direccion,
            direccionGoogle,
            referencia,
            telefono,
        };

        // Actualizar el estado
        setUserUbication(nuevaUbicacion);

        // Guardar en localStorage
        localStorage.setItem("userUbication", JSON.stringify(nuevaUbicacion));
    };

    const handleLatitude = (valor) => {
        setLatitude(valor);
    };

    const handleLongitude = (valor) => {
        setLongitude(valor);
    };

    const getLocation = () => {
        const defaultLatitude = -9.529850759267214;
        const defaultLongitude = -77.5288491820109;

        // Almacenar en localStorage
        localStorage.setItem("latitude", defaultLatitude.toString());
        localStorage.setItem("longitude", defaultLongitude.toString());
        // Actualizar los estados de latitud y longitud
        setLatitude(defaultLatitude);
        setLongitude(defaultLongitude);

        if (navigator.permissions && navigator.permissions.query) {
            navigator.permissions
                .query({ name: "geolocation" })
                .then((permissionStatus) => {
                    if (permissionStatus.state === "granted") {
                        navigator.geolocation.getCurrentPosition(
                            (position) => {
                                const latitude = position.coords.latitude;
                                const longitude = position.coords.longitude;
                                // Almacenar en localStorage
                                localStorage.setItem(
                                    "latitude",
                                    latitude.toString()
                                );
                                localStorage.setItem(
                                    "longitude",
                                    longitude.toString()
                                );
                                // Actualizar los estados de latitud y longitud
                                setLatitude(latitude);
                                setLongitude(longitude);
                            },
                            (error) => {
                                console.error(
                                    "Error al obtener la ubicación:",
                                    error.message
                                );
                                // Usar coordenadas por defecto si hay un error
                                setLatitude(defaultLatitude);
                                setLongitude(defaultLongitude);
                                localStorage.setItem(
                                    "latitude",
                                    defaultLatitude.toString()
                                );
                                localStorage.setItem(
                                    "longitude",
                                    defaultLongitude.toString()
                                );
                            }
                        );
                    } else if (permissionStatus.state === "prompt") {
                        console.log(
                            "El usuario aún no ha decidido si otorgar permisos de geolocalización."
                        );
                    } else {
                        console.log(
                            "El usuario ha denegado los permisos de geolocalización."
                        );
                        // Usar coordenadas por defecto si los permisos son denegados
                        setLatitude(defaultLatitude);
                        setLongitude(defaultLongitude);
                        localStorage.setItem(
                            "latitude",
                            defaultLatitude.toString()
                        );
                        localStorage.setItem(
                            "longitude",
                            defaultLongitude.toString()
                        );
                    }
                })
                .catch((error) => {
                    console.error("Error al consultar los permisos:", error);
                    // Usar coordenadas por defecto si ocurre algún error al consultar los permisos
                    setLatitude(defaultLatitude);
                    setLongitude(defaultLongitude);
                    localStorage.setItem(
                        "latitude",
                        defaultLatitude.toString()
                    );
                    localStorage.setItem(
                        "longitude",
                        defaultLongitude.toString()
                    );
                });
        } else {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const latitude = position.coords.latitude;
                    const longitude = position.coords.longitude;
                    localStorage.setItem("latitude", latitude.toString());
                    localStorage.setItem("longitude", longitude.toString());
                    setLatitude(latitude);
                    setLongitude(longitude);
                },
                (error) => {
                    console.error(
                        "Error al obtener la ubicación:",
                        error.message
                    );
                    // Usar coordenadas por defecto si hay un error
                    setLatitude(defaultLatitude);
                    setLongitude(defaultLongitude);
                    localStorage.setItem(
                        "latitude",
                        defaultLatitude.toString()
                    );
                    localStorage.setItem(
                        "longitude",
                        defaultLongitude.toString()
                    );
                }
            );
        }
    };

    const deleteLocation = () => {
        setLatitude(0);
        setLongitude(0);
        localStorage.removeItem("latitude");
        localStorage.removeItem(longitude);
    };

    const nuevaOrdenCliente = async (orden) => {
        try {
            // Envía la orden al backend usando un POST request
            const { data } = await clienteAxios.post(
                "/api/ordenes/nuevaOrdenCliente",
                orden
            );

            // Aquí podrías manejar la respuesta, actualizar el estado, etc.
            // Por ejemplo, si quieres guardar la respuesta en localStorage:
            //localStorage.setItem("ordenCliente", JSON.stringify(data));

            return null;
            // Opcional, si deseas devolver los datos
        } catch (error) {
            console.error("Error al crear la orden del cliente:", error);
        }
    };

    const obtenerTiendas = async () => {
        try {
            const { data } = await clienteAxios.get("/api/tienda/tiendas");
            setTiendas(data);
            localStorage.setItem("tiendas", JSON.stringify(data));
        } catch (error) {}
    };

    const handleCategoriaSelected = (categoria) => {
        setCategoriaSelected(categoria);
    };

    const obtenerTienda = async (ruta) => {
        setCargando(true);
        try {
            const { data } = await clienteAxios(`/api/tienda/${ruta}`); // Envía solo el valor de "ruta"
            setLocal(data);
        } catch (error) {
        } finally {
            setCargando(false);
        }
    };

    const obtenerProducto = async (productoId) => {
        setCargando(true);
        try {
            const { data } = await clienteAxios(
                `/api/tienda/obtenerProducto/${productoId}`
            ); // Envía solo el valor de "ruta"
            setProducto(data);
        } catch (error) {
        } finally {
            setCargando(false);
        }
    };

    const handleLocalState = async () => {
        setTiendaVista("");
    };

    const handleTiendaVista = async (id) => {
        setTiendaVista(id);
    };

    // const obtenerProductosPorId2 = async (id) => {
    //     try {
    //         const horaActual = new Date().getHours(); // Obtiene la hora actual en formato de 24 horas

    //         // Intenta obtener la hora almacenada en localStorage
    //         const horaAlmacenada = localStorage.getItem(`hora_solicitud_${id}`);

    //         if (!horaAlmacenada || parseInt(horaAlmacenada) !== horaActual) {
    //             // Si la hora almacenada es diferente de la hora actual o no existe, hacer la solicitud al API
    //             const { data } = await clienteAxios.post(
    //                 "/api/tienda/obtenerProductosPorTienda",
    //                 { idLocal: id }
    //             );

    //             // Establecer el estado de productos con los datos recibidos
    //             setProductos(data);

    //             // Guardar la hora actual en localStorage
    //             localStorage.setItem(
    //                 `hora_solicitud_${id}`,
    //                 horaActual.toString()
    //             );

    //             // Guardar los datos en localStorage para futuras consultas
    //             localStorage.setItem(`productos_${id}`, JSON.stringify(data));
    //         } else {
    //             // Si la hora almacenada es la misma que la hora actual, utilizar los datos existentes en localStorage
    //             const productosLocalStorage = localStorage.getItem(
    //                 `productos_${id}`
    //             );
    //             if (productosLocalStorage) {
    //                 setProductos(JSON.parse(productosLocalStorage));
    //             }
    //         }
    //     } catch (error) {
    //     } finally {
    //     }
    // };

    // const obtenerProductosPorId = async (id, version) => {
    //     try {
    //         // Realizar la solicitud a la API con idLocal y version
    //         const { data } = await clienteAxios.post(
    //             "/api/tienda/obtenerProductosPorTienda",
    //             { idLocal: id }
    //         );

    //         // Establecer el estado de productos con los datos recibidos
    //         setProductos(data);
    //     } catch (error) {
    //         if (error.response && error.response.status === 204) {
    //             console.log(
    //                 "La versión proporcionada es igual o mayor a la versión actual. No se retornaron productos."
    //             );
    //         } else {
    //             console.error("Error al obtener productos:", error);
    //         }
    //     } finally {
    //         // Aquí puedes realizar cualquier limpieza o acción final si es necesario
    //     }
    // };

    const limpiarProductosObsoletos = (id) => {
        const claves = Object.keys(localStorage);
        const versiones = {};
    
        // Recopilar las versiones de los productos para el id dado
        claves.forEach((clave) => {
            const match = clave.match(/^productos_(\w+)_(\d+)$/); // Cambié \d+ a \w+ para que coincida con el ID
            if (match && match[1] === id) {
                const version = parseInt(match[2]);
                versiones[version] = clave; // Guardamos la clave asociada a la versión
            }
        });
    
        // Verificar si se encontraron versiones
        
    
        // Determinar la versión más alta
        if (Object.keys(versiones).length > 0) {
            const versionMasAlta = Math.max(...Object.keys(versiones).map(Number));
    
            
    
            // Eliminar todas las versiones excepto la más alta
            Object.keys(versiones).forEach((version) => {
                if (parseInt(version) !== versionMasAlta) {
                    localStorage.removeItem(versiones[version]);
                    console.log(`Eliminando versión obsoleta: ${versiones[version]}`);
                }
            });
        } else {
            console.log("No se encontraron versiones para eliminar.");
        }
    };

    const obtenerProductosPorId = async (id, versionLocal) => {

        limpiarProductosObsoletos(id);


        try {
            // Consulta la versión de la carta
            const response = await clienteAxios.post(
                "/api/tienda/obtenerVersionCarta",
                { idLocal: id }
            );
            const versionActual = response.data.versionCarta;
            //return console.log("Versión actual de la carta:", versionActual, "Versión local de la carta:", versionLocal, "son iguales",  parseInt(versionActual) === parseInt(versionLocal));


            // Compara la versión actual con la versión almacenada
            if (parseInt(versionLocal) === parseInt(versionActual)) {
                const productosKey = `productos_${id}_${versionLocal}`;
                const productosAlmacenados = localStorage.getItem(productosKey);
                

                // Si hay productos en localStorage con la versión correcta
                if (productosAlmacenados) {
                    const productosParsed =
                        JSON.parse(productosAlmacenados).productos;
                    if (productosParsed && productosParsed.length > 0) {
                        
                        setProductos(productosParsed);
                        
                        return; // Salimos de la función, ya que los productos están actualizados
                    }
                }
            }

            

            // Si no hay productos en localStorage o versiones no coinciden, consultar a la API
            console.log("Consultando a la API para obtener productos...");
            const { data } = await clienteAxios.post(
                "/api/tienda/obtenerProductosPorTienda",
                { idLocal: id, version: versionLocal }
            );
            limpiarProductosObsoletos(id);

            // Verifica que los productos hayan sido devueltos
            if (data && data.productos) {
                setProductos(data.productos);
                localStorage.setItem(
                    `productos_${id}_${versionActual}`,
                    JSON.stringify(data)
                );
                console.log(
                    "Productos actualizados y almacenados en localStorage:",
                    data.productos
                );                
            } else {
                console.error(
                    "No se encontraron productos en la respuesta de la API."
                );
            }
        } catch (error) {
            console.error(
                "Error al obtener la versión o los productos:",
                error.message
            ); 
        }  finally {
            limpiarProductosObsoletos(id);
        }

    };

    const obtenerProductosPorCategoria = async (categoria) => {
        try {
            // Realiza la solicitud para obtener productos por categoría
            const { data } = await clienteAxios.post(
                "/api/tienda/obtenerProductosPorCategoria",
                { categoria: categoria }
            );

            // Establece el estado de productos con los datos recibidos
            setProductosPorCategoria(data);
        } catch (error) {}
    };

    const handleModalAgregarProducto = () => {
        setModalAgregarProducto(!modalAgregarProducto);
    };

    const handleMostrarCambioUbicacion = (valor) => {
        setMostrarCambioUbicacion(valor);
    };

    return (
        <ProductosContext.Provider
            value={{
                tiendas,
                obtenerTiendas,
                obtenerTienda,
                local,
                obtenerProductosPorId,
                productos,
                handleLocalState,
                handleTiendaVista,
                tiendaVista,
                modalAgregarProducto,
                handleModalAgregarProducto,
                obtenerProductosPorCategoria,
                productosPorCategoria,
                handleCategoriaSelected,
                categoriaSelected,
                obtenerProducto,
                producto,
                cargando,
                userUbication,
                latitude,
                longitude,
                getLocation,
                deleteLocation,
                guardarUbicacion,
                mostrarCambioUbicacion,
                handleMostrarCambioUbicacion,
                handleLatitude,
                handleLongitude,
                comApp,
                nuevaOrdenCliente,
            }}
        >
            {children}
        </ProductosContext.Provider>
    );
};

export { ProductosProvider };

export default ProductosContext;
