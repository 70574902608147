export function obtenerFechaActual() {
  // Obtener la fecha actual
  const fechaActual = new Date();

  // Obtener los componentes de la fecha
  const dia = fechaActual.getDate().toString().padStart(2, '0');
  const mes = (fechaActual.getMonth() + 1).toString().padStart(2, '0');
  const anio = fechaActual.getFullYear();



  // Formatear la fecha en el formato requerido (dd/mm/yyyy)
  const fechaFormateada = `${dia}/${mes}/${anio}`;

  return fechaFormateada;
}

export function obtenerHoraActual() {
  // Obtener la fecha actual
  const fechaActual = new Date();

  // Obtener los componentes de la hora
  let horas = fechaActual.getHours();
  const minutos = fechaActual.getMinutes().toString().padStart(2, '0');

  // Determinar si es AM o PM
  const amOpm = horas >= 12 ? 'pm' : 'am';

  // Convertir a formato de 12 horas
  horas = horas % 12;
  horas = horas ? horas : 12; // Si es medianoche, muestra 12 en lugar de 0

  // Formatear la hora en el formato requerido (hh:mm am/pm)
  const horaFormateada = `${horas}:${minutos} ${amOpm}`;

  return horaFormateada;
}

;

