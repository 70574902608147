
import Header from "../Header/Header.jsx";
import Routes from "../../routes/Routers";

import Carts from "../UI/cart/Carts.jsx";
import { useSelector } from "react-redux";
import GetLocation from "../../pages/GetLocation.jsx";
import useProductos from "../../hooks/useProductos.jsx";
import { useEffect, useState } from "react";
import { MoonLoader } from "react-spinners";

const Layout = () => {

  const { mostrarCambioUbicacion, handleMostrarCambioUbicacion, userUbication } = useProductos()

  const [isLoading, setIsLoading] = useState(true);

  

  useEffect(() => {
    // Verifica si userUbication tiene valores en las propiedades clave
    if (userUbication?.lat && userUbication?.long) {
      handleMostrarCambioUbicacion(true); // Llama a la función con true
    }

    setIsLoading(false);
  }, []);





  const showCart = useSelector((state) => state.cartUi.cartIsVisible);

  
  if (isLoading) {
    // Muestra un indicador de carga o un esqueleto mientras se carga
    return (
        <div
            style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 999, // Asegura que esté sobre otros elementos
            }}
        >
            <MoonLoader color="#d50505" size={70} speedMultiplier={0.7} />
        </div>
    );
}

  return (
    <div>
      <Header />

      {!mostrarCambioUbicacion ? <GetLocation /> : <div>
        {showCart && <Carts />}

        <div>
          <Routes />
        </div>
      </div>}












    </div >
  );
};

export default Layout;
