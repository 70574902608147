import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import Helmet from "../components/Helmet/Helmet";
import { Container, Col } from "reactstrap";
import {  useSelector } from "react-redux";

import "../styles/product-details.css";

import ProfileCard from "./ProfileCard";
import ProductCard2 from "../components/UI/product-card/ProductCard2";
import useProductos from "../hooks/useProductos";
import ProductDialog from "../components/UI/product-card/ProductDialog";
import { MoonLoader } from "react-spinners";

const LocalDetails = () => {
    const cartItems = useSelector((state) => state.cart.cartItems);
    const totalAmount = useSelector((state) => state.cart.totalAmount);
    const totalQuantity = useSelector((state) => state.cart.totalQuantity);

    const inputRef = useRef(null);
    const { tienda } = useParams();
    const { obtenerTienda, local, obtenerProductosPorId, productos, cargando } =
        useProductos();

    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        obtenerTienda(tienda);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (tienda === local.ruta) {
            // Buscar clave en localStorage que comience con `productos_${local._id}_`
            const productosClaveExistente = Object.keys(localStorage).find((key) =>
                key.startsWith(`productos_${local._id}_`)
            );
    
            // Extraer la versión de la clave si existe, o asignar null si no
            const versionAlmacenada = productosClaveExistente 
                ? productosClaveExistente.split('_').pop() 
                : null;
    
            // Llamar a la función obteniendo la versión almacenada o pasando null si no hay
            obtenerProductosPorId(local._id, versionAlmacenada);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [local]);

    

    
    

    const [pageNumber] = useState(0);

    const searchedProduct = (productos || []).filter((item) => {
        if (!searchTerm || searchTerm.trim() === "") {
            return true; // Devuelve true para todos los elementos si searchTerm es undefined o una cadena vacía.
        }
        return (
            item.nombre.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.categoria.toLowerCase().includes(searchTerm.toLowerCase())
        );
    });
    

    useEffect(() => {
        const scrollToTop = () => window.scrollTo(0, 0);
        scrollToTop();
    }, []);

    const productPerPage = 250;
    const visitedPage = pageNumber * productPerPage;
    const displayPage = searchedProduct.slice(
        visitedPage,
        visitedPage + productPerPage
    );

    const mapeoCategorias = (productos || []).map((cat) => cat.categoria);


    const categoria = mapeoCategorias.reduce((a, e) => {
        // eslint-disable-next-line eqeqeq
        if (!a.find((d) => d == e)) {
            a.push(e);
        }

        return a;
    }, []);

    useEffect(() => {
        setSearchTerm(categoria[0]);
    }, [productos]);

    const handleInputClick = () => {
        if (inputRef.current) {
            inputRef.current.select();
        }
    };

    if (cargando) {
        // Muestra un indicador de carga o un esqueleto mientras se carga
        return (
            <div
                style={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 999, // Asegura que esté sobre otros elementos
                }}
            >
                <MoonLoader color="#d50505" size={70} speedMultiplier={0.7} />
            </div>
        );
    }

    
    
    return (
        <Helmet title="Product-details">
            <section>
                <Container>
                    <div>
                        <ProfileCard local={local} />
                        <h1>Buscar producto:</h1>

                        <Col lg="6" md="6" sm="6" xs="12">
                            <div className="search__widget d-flex align-items-center container-search">
                                {searchTerm === "" && (
                                    <span>
                                        <i className="ri-search-line"></i>
                                    </span>
                                )}
                                <input
                                    type="text"
                                    placeholder="Buscar producto..."
                                    className="input-busqueda"
                                    value={searchTerm}
                                    onChange={(e) =>
                                        setSearchTerm(e.target.value)
                                    }
                                    onClick={handleInputClick}
                                    ref={inputRef}
                                />
                            </div>
                        </Col>

                        <div className="contenedor-categorias">
                            {categoria.map((cat) => (
                                <div className="contenedor-categoria">
                                    <div
                                        className={
                                            searchTerm?.toLowerCase() ===
                                            cat.toLowerCase()
                                                ? "navegacion-selected"
                                                : "navegacion"
                                        }
                                        key={cat.id}
                                        onClick={() => setSearchTerm(cat)}
                                    >
                                        <p>{cat}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {searchTerm && (
                            <h1 className="search-term">{searchTerm}:</h1>
                        )}

                        <div className="productos-container-general">
                            {displayPage.map((data) => (
                                // <ProductCard2 data={data}/>
                                <ProductCard2
                                    data={data}
                                    dataSub={data.subcategorias}
                                    local={local}
                                    adicionalPorTaper={
                                        local.adicionalPorTaper || 0
                                    }
                                />
                            ))}
                        </div>
                    </div>
                    <ProductDialog />
                    {cartItems.length > 0 && (
                        <div className="static-bar">
                            <div className="div-barra-bottom-text">
                                <p className="barra-bottom-p">
                                    {totalQuantity} producto
                                    {totalQuantity > 1 && "s"}{" "}
                                </p>
                                <p className="barra-bottom-precio">
                                    S/.{totalAmount.toFixed(2)}{" "}
                                </p>
                            </div>
                            <Link to="/checkout">
                                <div className="div-barra-bottom-button">
                                    <p>Ver mi pedido</p>
                                </div>
                            </Link>
                        </div>
                    )}
                </Container>
            </section>
        </Helmet>
    );
};

export default LocalDetails;
